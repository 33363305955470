<template>
	<div class="book-info-repository">
		<div class="filter" v-if="recommendData.length > 1">
			<div>
				<cust-select
					v-model="filterOrder"
					:optionList="recommendData"
					@change="filterOrderChange"
				>
					<template slot="option" slot-scope="{ item }">{{
						item.display_name
					}}</template>
				</cust-select>
			</div>
		</div>
		<bookinfo-repository :datas="tableData" />
	</div>
</template>

<script>
import { compare } from "@/utils/dataProcess";
import { bookInfoAction } from "@/api/ebookSystem.js";

export default {
	components: {
		custSelect: () => import("@/components/custSelect.vue"),
		bookinfoRepository: () => import("../components/bookinfoRepository2.vue")
	},
	data() {
		return {
			tableData: [],
			filterOrder: 0,
			recommendData: [
				{
					order: 0,
					display_name: "最新上架",
					value: "newest"
				},
				{
					order: 1,
					display_name: "最新出版",
					value: "publish"
				},
				{
					order: 2,
					display_name: "借閱排行",
					value: "hottest"
				}
			]
		};
	},
	mounted() {
		this.filterOrder = this.$route.params.idx;
		this.getData();
	},
	methods: {
		filterOrderChange(val) {
			const idx = val;
			this.$router.push({ name: this.$route.name, params: { idx } });
			this.getData();
		},
		getData() {
			this.pk = this.$route.params.idx;
			let query = {};
			query[this.pk] = 100;
			bookInfoAction
				.guestPlatform(query)
				.then(res => {
					this.tableData = [];
					_.each(res.data, o => {
						this.tableData.push({
							ISBN: o["ISBN"],
							bookname: o["bookname"],
							bookbinding: o["bookbinding"],
							order: o["order"],
							author: o["author"],
							house: o["house"],
							date: o["date"],
							action: o["ISBN"],
							finish_date: o["finish_date"]
						});
					});
					// this.tableData.sort(compare('date', 'desc'));
				})
				.catch(res => {
					this.$errorHandler({ error: res });
				});
		}
	}
};
</script>

<style scoped lang="scss">
.book-info-repository {
	margin: 0 -15px;
	.filter {
		display: flex;
		justify-content: flex-end;
		padding-right: 10px;
	}

	// *Purpose: Fix a wierd bug that .btnn-group functioning out of expect,
	// the width is too small than expect, don't know why.
	::v-deep .table-div {
		.btnn-group {
			min-width: 4.4em;
		}
	}
}
</style>
